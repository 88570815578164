.table{
    thead{
        :host-context( .theme--dark ) & {
            background-color: #6c6e6d;
        }
    }
    .shrink{
        width: .1%;
    }
    th, td{
        vertical-align: top;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 14px;
        padding-bottom: 14px;
        position: relative;
    }
    td{
        border-bottom: 1px solid var(--shade-1-fixed);
        border-bottom: 1px solid #E6E6E6;
        font-size: $fontsize-s;
        line-height: 21px;
        font-weight: 400;
        font-weight: 300;
    }
    th{
        z-index: 1;

        &.sortable{
            cursor: pointer;
        }
        &.settings{
            cursor: pointer;
            display: table-cell;
            padding-left: 5px;
        }
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            background-color: var(--border-gray);
            left: 0;
            bottom: -1px;
            z-index: 1;
            display: none;
            :host-context( .theme--dark ) & {
                background-color: #6c6e6d;
                height: calc(100% + 1px);
            }
        }
        &:first-of-type{
            &::after{
                // left: -$spacing-xs;
                // width: calc(100% + #{$spacing-xs});
                bottom: -1px;
                // opacity: 0;
                display: none;
            }
            padding-left: 0;
        }
        &:last-of-type{
            &::after{
                // left: 0;
                // width: calc(100% + #{$spacing-xs});
                bottom: -1px;
                // opacity: 0;
                display: none;
            }
            padding-right: 0;
        }
        &>div{
            white-space: nowrap;
        }
        // label color
        color: var(--secondary);
        // text-transform: uppercase;
        // font-weight: 500;
        font-weight: 400;
        font-size: $fontsize-s;
        // border: none;
        border-top: none;
        &>div{
            z-index: 2;
        }
        border-bottom: 2px solid var(--border-gray) !important;
    }
    tr{
        transition: .1s ease;
        position: relative;
        &.clickable{
            cursor: pointer;
            &:hover td{
                // border-bottom-color: var(--secondary);
                // background-color: #FDFDFD !important;
                // color: var(--secondary) !important;
                // .title{
                //     color: var(--secondary) !important;
                // }
                background-color: #F4F6F9;
            }
        }
        td {
            color: var(--shade-6);
            // transition: .1s ease;
            &:first-of-type::after{
                content: '';
                position: absolute;
                // background: var(--highlight-background);
                top: -1px;
                left: -$spacing-xs;
                height: calc(100% + 2px);
                width: $spacing-xs;
                opacity: 0;
                display: none;
                // transition: .1s ease;
            }
            &:last-of-type::after{
                content: '';
                position: absolute;
                // background: var(--highlight-background);
                top: -1px;
                right: -$spacing-xs;
                height: calc(100% + 2px);
                width: $spacing-xs;
                opacity: 0;
                display: none;
                // transition: .1s ease;
            }
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-left: 0;
            }
        }
        &.active td, &:hover td{
            // background: var(--highlight-background);
            &::after{
                // transition: .1s ease;
            }
            &:first-of-type::after{
                opacity: 1;
            }
            &:last-of-type::after{
                opacity: 1;
            }
        }
        // &:hover td{
        //     border-bottom-color: var(--secondary);
        //     background-color: #FDFDFD !important;
        //     color: var(--secondary) !important;
        //     .title{
        //         color: var(--secondary) !important;
        //     }
        // }

        .title {
            font-weight: 400;
            .app-tooltip-wrap{
                padding-left: 4px;
            }
        }
    }
    .action-button{
        cursor: pointer;
        color: var(--text-color);
        font-size: $fontsize-xxs;
        text-decoration: none !important;
        background-color: #EEF2F5;
        width: 24px;
        height: 24px;
        border-radius: 99px;
        transition: .1s ease;
        &.action-active{
            background-color: #EEF2F5 !important;
        }
        &:hover{
            // color: var(--shade-7);
            background-color: #dde5ea;
        }
        &>i{
            // padding: 5px;
            font-size: 14px;
            // transition: .05s ease;
            padding: 1px 5px;
        }
    }
    .desc::after{
        content: "";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid var(--primary-color);
        position: absolute;
        top: 5px;
        transform: translatex(6px);
        :host-context( .theme--dark ) & {
            border-top-color: var(--shade-7);
        }
    }

    .asc::after{
        content: "";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid var(--primary-color);
        position: absolute;
        top: 5px;
        transform: translatex(6px);
        :host-context( .theme--dark ) & {
            border-bottom-color: var(--shade-7);
        }
    }
    .none::after{
        content: url(/assets/img/icons/sort-none.svg);
        top: 2px;
        position: absolute;
        margin-left: 4px;
    }
    a{
        color: var(--shade-6);
    }
    .bullet--new:before{
        content: "•";
        color: var(--blue);
        font-size: x-large;
        margin-left: 6px;
        transform: translateY(3px);
        display: inline-block;
    }
}

.td-filename{
    padding-left: 8px !important;
}

.table-empty-line{
    td{
        border-bottom: none !important;
    }
}

.table--detail{
    width: 100%;
    .app-list{
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        li{
            border-bottom: none !important;
            border-top: 1px solid #E6E6E6;
            // color: #7C8590 !important;
            color: var(--shade-5) !important;
            &:last-of-type{
                padding-bottom: 0;
            }
        }
    }
    &.table--detail--clickable{
        &>tr{
            &>td{
                cursor: pointer;
            }
            &:hover{
                td{
                    background-color: rgba(244, 246, 249, .4);
                }
            }
        }
    }
    &>tr{
        &>th{
            color: var(--secondary);
            font-size: $fontsize-s;
            line-height: 20px;
            // text-transform: uppercase;
            // font-weight: 500;
            // padding-left: 16px;
            // padding-right: 16px;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            // border-bottom: 2px solid #F5F5F5;
            background-color: rgba(244, 246, 249, 1);
            &:first-of-type{
                border-top-left-radius: 4px;
                // padding-left: 0;
            }
            &:last-of-type{
                border-top-right-radius: 4px;
                // padding-right: 0;
            }
        }
        &>td{
            transition: .1s ease;
            font-size: $fontsize-s;
            line-height: 20px;
            // border-bottom: 1px solid #F5F5F5;
            border-bottom: 1px solid rgba(244, 246, 249, 1);
            border-bottom: 1px solid #E6E6E6;
            color: #7C8590;
            padding-top: 12px;
            padding-bottom: 12px;
            // padding-left: 16px;
            // padding-right: 16px;
            padding-left: 12px;
            padding-right: 12px;
            &.td--label{
                color: var(--secondary);
            }
            &:first-of-type{
                // padding-left: 0;
            }
            &:last-of-type{
                // padding-right: 0;
            }
        }
    }
    &.table--detail--form{
        &>tr{
            &>th{
                padding-top: 8px;
                padding-bottom: 8px;
                // border-bottom: none !important;
                &:first-of-type{
                    // padding-left: 0;
                }
                &:last-of-type{
                    // padding-right: 16px;
                }
            }
            &>td{
                padding-top: 8px;
                padding-bottom: 8px;
                // border-bottom: none;
                &:first-of-type{
                    // padding-left: 0;
                }
                &:last-of-type{
                    // padding-right: 16px;
                }
            }
        }
    }
}

table{
    td{
        vertical-align: top;
    }
}
.table td *{
    padding: 0;
}