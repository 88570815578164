.read-only{
    .ql-editor{
        padding: 0%;
        ul{
            padding-left: 0;
        }
    }
}

.ql-container{
    font-size: 100%;
}

.ql-container:not(.ql-disabled) .ql-editor{
    min-height: 90px;;
}
quill-editor{
    width: 100%;
}
quill-editor .ql-toolbar.ql-snow{
    border: 1px solid #ACB5C1 !important;
    border-bottom: none !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
quill-editor .ql-container.ql-snow{
    border: 1px solid #ACB5C1 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.products quill-editor .ql-container.ql-snow{
    background: #FFF;
}