.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease;
	animation-duration: .2s;
}
.fade-in-shimmer {
	opacity: 0;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease;
    // animation-duration: .2s;
    animation-fill-mode: forwards;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


// modals

.modal-content{
	// animation-name: modalAnimation;
	// animation-duration: .1s;
}

@keyframes modalAnimation {
	0%   {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal-backdrop{
	// animation-name: modalAnimationBackdrop;
    // animation-duration: 4s;
    // animation-fill-mode: forwards;
    background-color: #5e5e5e78;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    opacity: 1 !important;
}

@keyframes modalAnimationBackdrop {
	0%   {
		opacity: 0 !important;
	}
	100% {
		opacity: 1 !important;
	}
}